@media (orientation:landscape) and (min-width:1490px)
{

    #diaporama-introduction .diaporama-item-body
    {
        width:42%;
        min-width:700px;
        max-width:none;
    }

    /* width */
    .diaporama-item-body
    {
        width:40%;
        max-width:640px;
    }

    /* padding */
    .diaporama-item-content
    {
        padding:0 8%;
    }

}

@media (orientation:landscape) and (max-width:1490px)
{

    /* width */
    .diaporama-item-body
    {
        width:550px;
    }

    #diaporama-introduction .diaporama-item-body
    {
        width:640px;
    }

    /* padding */
    .diaporama-item-content
    {
        padding:0 5%;
    }

}

@media (orientation:landscape) and (max-width:1280px)
{

    /* width */
    .diaporama-item-body
    {
        width:39%;
    }

    #diaporama-introduction .diaporama-item-body
    {
        width:50%;
    }

    /* padding */
    .diaporama-item-content
    {
        padding:0;
    }

    .button-share-list>li
    {   
        float:none;
        text-align:center!important;
        width:100%;
        margin-top:1em;
    }

    .button-share-list>li:nth-child(1)
    {
        margin-top:0;
    }

}

@media (orientation:landscape) and (max-width:1024px)
{

    #diaporama-item-list
    {
        right:100px;
    }

    .diaporama-item-control,
    .diaporama-item-control:hover,
    .diaporama-item-control:focus,
    .diaporama-item-control:active
    {
        width:100px;
    }

    .diaporama-item-body
    {
        left:100px;
    }

    .diaporama-item-background
    {
        left:100px;
    }

    .diaporama-item-body>div>div
    {
        padding : 25px 25px 0 0; 
    }

}

@media (orientation:landscape) and (max-width:740px)
{

    h1
    {
        text-align:center;
        font-size:1.8em;
    }

    #diaporama-introduction h1.title
    {
        font-size:2.8em;
    }

}

@media (orientation:portrait)
{

    #diaporama-control-list
    {
        display:block;
    }

    #diaporama-item-list
    {
        right:0;
    }

    .diaporama-item-control,
    .diaporama-item-control:hover,
    .diaporama-item-control:focus,
    .diaporama-item-control:active
    {
        display:none!important;
    }

    .diaporama-item-body
    {
        left:0!important;
        right:0!important;
        top:60vw;
        padding-top:94px;
        box-sizing: border-box;
        height:auto!important;
        bottom:0!important;
        width:100%!important;
    }

    .diaporama-item-background
    { 
        bottom:auto;
        height:60vw;
        width:100%;
        left:0;
        background-attachment: local;
    }

    .diaporama-item-body>div>div
    {
        padding : 0!important; 
    }

    .diaporama-item a.logo.link-to-gustave-et-rosalie
    {
        display:none;
    }

    /* padding */
    .diaporama-item-content
    {
        padding:0 6%;
    }

    h1
    {
        text-align : center;
        font-size  : 1.8em;
        margin     : 0 0 1em 0;
    }

    #diaporama-introduction h1.title
    {
        font-size:2.5em;
    }

}

@media (orientation:portrait) and (max-width:550px)
{

    .button-share-list>li
    {   
        float:none;
        text-align:center!important;
        width:100%;
        margin-top:1em;
    }

    .button-share-list>li:nth-child(1)
    {
        margin-top:0;
    }  

}

@media (orientation:portrait) and (min-width:412px)
{

    /* padding */
    .diaporama-item-content
    {
        padding:0 7%;
    }

}

@media (orientation:portrait) and (min-width:600px)
{

    body
    {
        font-size:15px;
    }

    /* padding */
    .diaporama-item-content
    {
        padding:0 8%;
    }

    #diaporama-control-list .diaporama-control.back
    {
       background-position-x: 37%;
    }
    
    #diaporama-control-list .diaporama-control.next
    {
        background-position-x: 37%;
    }

}

@media (orientation:portrait) and (min-width:720px)
{

    body
    {
        font-size:16px;
    }

    /* padding */
    .diaporama-item-content
    {
        padding:0 9%;
    }

}

@media (orientation:portrait) and (min-width:800px)
{

    body
    {
        font-size:17px;
    }

    .diaporama-item-content
    {
        padding:0 12%;
    }

    #diaporama-control-list
    {
        margin-top:-99px;
        height:100px;
    }
    
    #diaporama-control-list>li
    {
        height:100px;
    }
    
    #diaporama-control-list a.logo.link-to-gustave-et-rosalie
    {
        width   : 50px;
        height  : 100px;
        background-size:50px auto;
    }

    #diaporama-control-list a.logo.link-to-gustave-et-rosalie.initial
    {
        width   : 66px;
        background-size:66px auto;
    }
    
    #diaporama-control-list .diaporama-control
    {
        height:100px;
        background-size:auto 45px;
    }

}

@media (orientation:portrait) and (min-width:1024px)
{

    .diaporama-item-content
    {
        padding:0 16%;
    }

}