@import "./Reset.css";
@import "./Fonts.css";
@import "./Text.css";
@import "./Button.css";
@import "./ButtonShare.css";
@import "./ButtonShareList.css";
@import "./Item.css";
@import "./ItemBackground.css";
@import "./ItemControl.css";
@import "./ItemIntroduction.css";
@import "./Controls.css";
@import "./Responsive.css";

html 
{
    background : #FFF;
}

body
{
    overflow-x : hidden; 
    overflow-y : auto;
    background : #FFF;
}

#main
{
    position : absolute; 
    top      : 0; 
    bottom   : 0; 
    left     : 0; 
    right    : 0;
    width    : 100%; 
    height   : 100%; 
    overflow : hidden;
}
