.button-share-list
{
    margin:4em auto 4em auto;
    max-width:490px;
}

.button-share-list:after
{
    clear: both;
    content:'';
    display: table;
    height:0;
    overflow: hidden;
}

.button-share-list>li
{
    float:left; 
    display:block; 
    width:33.333333%; 
    text-align:center;
}

.button-share-list>li:nth-child(1)
{
    text-align:left;
}

.button-share-list>li:last-child
{
    text-align:right;
}