#diaporama-introduction .diaporama-item-body>div
{
    padding-bottom : 4em;
}

#diaporama-introduction .diaporama-item-body>div>div
{
    padding-left:33px;
}

#diaporama-introduction .diaporama-item-background
{
    left:0;
    right:-116px;
}

#diaporama-introduction a.logo.link-to-gustave-et-rosalie
{
    width:76px;
    height:80px;
    margin: 0 auto 2.25em auto;
    background-size:auto 76px;
}

#diaporama-introduction h1.title
{
    font-size      : 3em;
    text-align     : center;
    position       : relative;
    margin         : 0 0 1.3em 0;
}

#diaporama-introduction h1.title:after
{
    content:'';
    display:block;
    background-image: url('./../Images/Cross.png');
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%; 
    background-size : auto 11px;
    position:absolute;
    bottom:-0.825em;
    left:0;
    right:0;
    height:11px;
}

#diaporama-introduction .footer
{
    position:absolute; 
    left:0;
    right:0;
    width:100%;
    bottom:0;
    height:4em;
}

#diaporama-introduction .footer>p
{
    text-align:center; 
    margin:0;
    padding:0;
}

#link-to-start
{
    text-align:center; 
    margin-top:2em;
}

#diaporama-introduction.current .diaporama-item-control:after
{
    background: url('./../Images/Arrow.L.White.svg') 50% 50% no-repeat;
}

#diaporama-introduction.current .diaporama-item-control,
#diaporama-introduction.current + .diaporama-item .diaporama-item-control
{
    background-color:rgba(0,0,0,0.15);
    color:#FFF;
}

#diaporama-introduction.current + .diaporama-item .diaporama-item-control:after
{
    background: url('./../Images/Arrow.R.White.svg') 50% 50% no-repeat;
}

#diaporama-introduction .diaporama-item-control
{
    transition-property: background-color, color;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
    transition-delay:0ms;
    -moz-transition-property: background-color, color;
    -moz-transition-duration: 300ms;
    -moz-transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
    -moz-transition-delay:0ms;
    -webkit-transition-property: background-color, color;
    -webkit-transition-duration: 300ms;
    -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
    -webkit-transition-delay:0ms;
    -ms-transition-property: background-color, color;
    -ms-transition-duration: 300ms;
    -ms-transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
    -ms-transition-delay:0ms;
    -o-transition-property: background-color, color;
    -o-transition-duration: 300ms;
    -o-transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
    -o-transition-delay:0ms;
}

#diaporama-introduction + .diaporama-item .diaporama-item-control 
{
    transition-property: background-color, color;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
    transition-delay:0ms;
    -webkit-transition-property: background-color, color;
    -webkit-transition-duration: 300ms;
    -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
    -webkit-transition-delay:0ms;
    -moz-transition-property: background-color, color;
    -moz-transition-duration: 300ms;
    -moz-transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
    -moz-transition-delay:0ms;
    -ms-transition-property: background-color, color;
    -ms-transition-duration: 300ms;
    -ms-transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
    -ms-transition-delay:0ms;
    -o-transition-property: background-color, color;
    -o-transition-duration: 300ms;
    -o-transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
    -o-transition-delay:0ms;
}

