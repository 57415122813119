a.button-share,
a.button-share:hover,
a.button-share:focus,
a.button-share:active
{
    display:inline-block;
    position: relative;
    border-width:2px;
    border-style:solid;
    border-color:#000;
    background-color: #FFF; 
    text-align: center;
    font-size:0.5em;
    min-width :138px;
    box-sizing: border-box;
    font-family: 'Gotham Bold';
    font-weight: 350;
    color:#000;
    line-height:38px;
    text-indent: 38px;
    padding:0 2em;
    text-decoration: none;
    text-transform: uppercase;
    transition-property         : background-color , color, border-color;
    transition-duration         : 0.41s;
    transition-delay            : 0.05s;
    -moz-transition-property    : background-color , color, border-color;
    -moz-transition-duration    : 0.41s;
    -moz-transition-delay       : 0.05s;
    -webkit-transition-property : background-color , color, border-color;
    -webkit-transition-duration : 0.41s;
    -webkit-transition-delay    : 0.05s;
    -ms-transition-property     : background-color , color, border-color;
    -ms-transition-duration     : 0.41s;
    -ms-transition-delay        : 0.05s;
    -o-transition-property      : background-color , color, border-color;
    -o-transition-duration      : 0.41s;
    -o-transition-delay         : 0.05s;
}

a.button-share:before,
a.button-share:hover:before,
a.button-share:focus:before,
a.button-share:active:before
{
    content                     :'+';
    text-indent                 : 0;
    text-align                  : center;
    border-right-width          : 2px;
    border-right-style          : solid;
    border-right-color          : #000;
    font-size                   : 24px;
    left                        : 0;
    top                         : 0;
    bottom                      : 0;
    display                     : block;
    position                    : absolute;
    height                      : 38px;
    width                       : 38px;
    font-family                 : Arial, Helvetica, sans-serif;
    font-weight                 : 200;
    transition-property         : border-color;
    transition-duration         : 0.41s;
    transition-delay            : 0.05s;
    -moz-transition-property    : border-color;
    -moz-transition-duration    : 0.41s;
    -moz-transition-delay       : 0.05s;
    -webkit-transition-property : border-color;
    -webkit-transition-duration : 0.41s;
    -webkit-transition-delay    : 0.05s;
    -ms-transition-property     : border-color;
    -ms-transition-duration     : 0.41s;
    -ms-transition-delay        : 0.05s;
    -o-transition-property      : border-color;
    -o-transition-duration      : 0.41s;
    -o-transition-delay         : 0.05s;
}

a.button-share:hover,
a.button-share:active
{
    color            : #FFF;
    background-color : #000;
    border-color     : #FFF;
}

a.button-share:hover:before,
a.button-share:active:before
{
    border-right-color : #FFF;
}