.diaporama-item-control,
.diaporama-item-control:hover,
.diaporama-item-control:focus,
.diaporama-item-control:active
{
    background-color:#FFF;
    text-decoration: none;
    display:block;
    color:#000;
    position: absolute; 
    top:0; 
    bottom:0; 
    left:0; 
    width:116px;
    cursor: pointer; 
}

.diaporama-item-control:after
{
    content:'';
    display:block;
    background: url('./../Images/Arrow.R.svg') 50% 50% no-repeat;
    width:70px;
    height:30px;
    position:absolute;
    top:50%;
    left:50%;
    margin:-58px 0 0 -35px;
}

.diaporama-item-control:before
{
    content:'suivant';
    display:block;
    font-family: 'Gotham Bold';
    text-transform: uppercase;
    position:absolute;
    top:50%;
    left:50%;
    font-size:9px;
    transform: translate(-50%,-50%) rotate(90deg);
    -o-transform: translate(-50%,-50%) rotate(90deg);
    -ms-transform: translate(-50%,-50%) rotate(90deg);
    -webkit-transform: translate(-50%,-50%) rotate(90deg);
    -moz-transform: translate(-50%,-50%) rotate(90deg);
}

.diaporama-item.current .diaporama-item-control:after
{
    background: url('./../Images/Arrow.L.svg') 50% 50% no-repeat;
}

.diaporama-item.current .diaporama-item-control:before
{
    content:'précédent';
    transform: translate(-50%,-50%) rotate(-90deg);
    -o-transform: translate(-50%,-50%) rotate(-90deg);
    -ms-transform: translate(-50%,-50%) rotate(-90deg);
    -webkit-transform: translate(-50%,-50%) rotate(-90deg);
    -moz-transform: translate(-50%,-50%) rotate(-90deg);
}