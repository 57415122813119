.diaporama-item-background
{
    position: absolute; 
    background-color:#AAA;
    top:0;  
    bottom:0;
    height:100%;
    left:116px;
    right:0;
    background-size:cover;
    background-position-x: 50%;
    background-position-y: 50%; 
    background-repeat: no-repeat;
    background-attachment: fixed;
}