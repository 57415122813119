#diaporama-control-list
{
    display:none;
    position:absolute;
    top:60vw;
    margin-top:0;
    height:94px;
    background:#FFF;
    left:0;
    right:0;
    width:100%;
    z-index:9;
}

#diaporama-control-list:after
{
    clear: both;
    height:0;
    content:'';
    overflow: hidden;
    display: table;
}

#diaporama-control-list>li
{
    float:left;
    width:33.33333333%;
    height:94px;
    text-align:center;
}

#diaporama-control-list a.logo.link-to-gustave-et-rosalie
{
    display : block;
    margin  : auto;
    width   : 38px;
    height  : 92px;
    background-color : transparent;
    background-image: url('./../Images/Gustave.svg');
    background-repeat: no-repeat;
    background-position-x:50%;
    background-position-y:50%;
    background-size:38px auto;
}

#diaporama-control-list a.logo.link-to-gustave-et-rosalie
{
    transition-duration: 250ms;
    transition-property: background-size , width;
    -ms-transition-duration: 250ms;
    -ms-transition-property: background-size , width;
    -o-transition-duration: 250ms;
    -o-transition-property: background-size , width;
    -webkit-transition-duration: 250ms;
    -webkit-transition-property: background-size , width;
    -moz-transition-duration: 250ms;
    -moz-transition-property: background-size , width;
}

#diaporama-control-list a.logo.link-to-gustave-et-rosalie.initial 
{
    width : 57px;
    background-size:57px auto;
}

#diaporama-control-list .diaporama-control
{
    display:block;
    height:94px;
    width:100%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size:64px auto;
}

#diaporama-control-list .diaporama-control.back
{
   background-image:url('./../Images/Arrow.L.svg');
   background-position-x: 20%;
}

#diaporama-control-list .diaporama-control.next
{
    background-image:url('./../Images/Arrow.R.svg');
    background-position-x: 80%;
}

