
@font-face {

    font-family  : 'Cooper Black';
    
    src          : local('Cooper Black')
                 , local('Cooper-Black')
                 , local('CooperBlack')
                 , url('./../Fonts/Cooper-Black.eot?#iefix')             format('embedded-opentype')
                 , url('./../Fonts/Cooper-Black.otf')                    format('opentype')
                 , url('./../Fonts/Cooper-Black.woff')                   format('woff')
                 , url('./../Fonts/Cooper-Black.ttf')                    format('truetype')
                 , url('./../Fonts/Cooper-Black.svg#Cooper-Black')       format('svg');
    
    font-weight  : 400;
    font-style   : normal;
    font-stretch : normal;

}

@font-face {

    font-family  : 'Gotham Bold';

    src          : local('Gotham Bold')
                 , local('Gotham-Bold') 
                 , local('GothamBold') 
                 , url('./../Fonts/Gotham-Bold.eot?#iefix')             format('embedded-opentype')
                 , url('./../Fonts/Gotham-Bold.otf')                    format('opentype')
                 , url('./../Fonts/Gotham-Bold.woff')                   format('woff')
                 , url('./../Fonts/Gotham-Bold.ttf')                    format('truetype')
                 , url('./../Fonts/Gotham-Bold.svg#Gotham-Bold')        format('svg');

    font-weight  : 400;
    font-style   : normal;
    font-stretch : normal;

}
    
@font-face {

    font-family  : 'Gotham Book';

    src          : local('Gotham Book')
                 , local('Gotham-Book') 
                 , local('GothamBook') 
                 , url('./../Fonts/Gotham-Book.eot?#iefix')             format('embedded-opentype')
                 , url('./../Fonts/Gotham-Book.otf')                    format('opentype')
                 , url('./../Fonts/Gotham-Book.woff')                   format('woff')
                 , url('./../Fonts/Gotham-Book.ttf')                    format('truetype')
                 , url('./../Fonts/Gotham-Book.svg#Gotham-Book')        format('svg');

    font-weight  : 325;
    font-style   : normal;
    font-stretch : normal;

}
  