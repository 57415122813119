html 
{
    background-color         : #FFF;
    font-size                : 100%;
    line-height              : 1.3;
    -moz-osx-font-smoothing  : grayscale;
    -webkit-font-smoothing   : antialiased;
    text-rendering           : optimizeLegibility;
    text-size-adjust         : auto; /* 100% ?? */
    -webkit-text-size-adjust : auto;
    -moz-text-size-adjust    : auto;
    -ms-text-size-adjust     : auto;
}

body,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
strong,
small,
em,
header, 
nav, 
section, 
article, 
aside, 
footer,
button,
a,
hr,
video,
ifram,
table,
td,
th
{
    margin          : 0;
    padding         : 0;
    border          : 0;
    list-style      : none;
    font-family     : inherit;
    font-style      : normal;
    font-weight     : 400;
    font-stretch    : normal;
    font-size       : 100%;
    line-height     : inherit;
    outline         : 0;
    text-decoration : none;
    text-align      : left;
    color           : inherit;
    background      : none;
    border-collapse : collapse;
    border-spacing  : 0;
}

*:focus
{
    outline : none;
}

ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
header, 
nav, 
section, 
article, 
aside, 
footer,
hr,
video
{
    display:block;
}

strong,
small,
em,
button,
a
{
    display : inline;
}

button,
button:hover,
button:active,
button:focus
a,
a:hover,
a:active,
a:focus
{
    cursor : pointer;
}

img,
audio,
video 
{
  height    : auto;
  max-width : 100%;
}

a>img
{
    border : none;
}