body
{
    font-family : 'Times New Roman', Times, serif;
    font-size   : 17px;
    line-height : 1.36em;
}

h1
{
    font-family : 'Cooper Black';
    font-size   : 2.3em;
    line-height : 1em;
    margin      : 0 0 0.8em 0;
}

h2 
{

    font-family    : 'Gotham Bold', cursive;
    position       : relative;
    text-transform : uppercase;
    font-size      : 0.66em;
    margin         : 0 0 1.6em 0;
}

h2:after 
{

    content    : '';
    display    : block;
    width      : 60px;
    height     : 0.18em;
    background : #000;
    position   : absolute;
    bottom     : -0.8em;
}

p
{
    text-align : justify;
    margin     : 0 0 1.2em 0;
}

span.underline
{
    text-decoration: underline;
}

a
{
    color : #09F;
}

a:hover
{
    color : #07A;
    text-decoration: underline;
}