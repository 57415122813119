#diaporama-item-list
{
    position:absolute; 
    top:0; 
    bottom:0; 
    left:0; 
    right:116px; /* 116px = control width */
    overflow:visible;
}

.diaporama-item
{
    position:absolute; 
    top:0; 
    bottom:0; 
    left:0;
    right:0;
    width:100%;
    height:100%;
    overflow:visible;
}

.diaporama-item-body
{
    background:#FFF; 
    position:absolute; 
    height:100%; 
    width:36%; 
    left:116px;
    overflow-x: hidden;
    overflow-y: auto;
}

.diaporama-item-body>div
{
    position   : relative; 
    min-height : 100%;
    box-sizing : border-box;
    padding    : 0 0 1.5em 0;
    margin     : 0;
}

.diaporama-item-body>div>div
{
    padding : 33px 33px 0 0; 
}

.diaporama-item-content
{
    padding:0;
}

.diaporama-item a.logo.link-to-gustave-et-rosalie
{
    display : block;
    width   : 44px;
    height  : 48px;
    margin  : 0 0 1.8em 0;
    padding : 0;
    background-color : transparent;
    background-image: url('./../Images/Gustave.svg');
    background-repeat: no-repeat;
    background-position-x:50%;
    background-position-y:50%;
    background-size:auto 44px;
}
