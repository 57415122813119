button,
button:hover,
button:active,
button:focus,
a.button,
a.button:hover,
a.button:active,
a.button:focus
{
    font-family                 : 'Gotham Bold', sans-serif;
    font-size                   : 0.645em;
    font-stretch                : normal;
    font-style                  : normal;
    font-weight                 : 350;
    display                     : inline-block;
    text-decoration             : none;
    line-height                 : 4em;
    padding                     : 0 3.35em;
    min-width :148px;
    box-sizing: border-box;
    text-align: center;
    text-transform              : uppercase;
    cursor                      : pointer;
    background-color            : transparent;
    color                       : #000;
    border-width:2px;
    border-style:solid;
    border-color:#000;
    transition-property         : background-color , color , border-color;
    transition-duration         : 0.28s;
    transition-delay            : 0.05s;
    -moz-transition-property    : background-color , color , border-color;
    -moz-transition-duration    : 0.28s;
    -moz-transition-delay       : 0.05s;
    -webkit-transition-property : background-color , color , border-color;
    -webkit-transition-duration : 0.28s;
    -webkit-transition-delay    : 0.05s;
    -ms-transition-property     : background-color , color , border-color;
    -ms-transition-duration     : 0.28s;
    -ms-transition-delay        : 0.05s;
    -o-transition-property      : background-color , color , border-color;
    -o-transition-duration      : 0.28s;
    -o-transition-delay         : 0.05s;
}

button:hover,
button:active,
a.button:hover,
a.button:active
{
    background-color : #FFF;
    color            : #000;
    border-color     : #000;
}

button.alt-1,
button.alt-1:hover,
button.alt-1:active,
button.alt-1:focus,
a.button.alt-1,
a.button.alt-1:hover,
a.button.alt-1:active,
a.button.alt-1:focus
{
    background-color : #000;
    color            : #FFF;
    border-color     : transparent;
}

button.alt-1:hover,
button.alt-1:active,
a.button.alt-1:hover,
a.button.alt-1:active
{
    background-color : #363636;
    color            : #EFEFEF;
    border-color     : #111;
}

button.alt-2,
button.alt-2:hover,
button.alt-2:active,
button.alt-2:focus,
a.button.alt-2,
a.button.alt-2:hover,
a.button.alt-2:active,
a.button.alt-2:focus
{
    background-color : transparent;
    color            : #FFF;
    border-color     : #FFF;
}

button.alt-2:hover,
button.alt-2:active,
a.button.alt-2:hover,
a.button.alt-2:active
{
    background-color : transparent;
    color            : #000;
    border-color     : #000;
}